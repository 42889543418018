import { IntlShape } from 'react-intl';

import { AppLabels } from './AppLabels';
import ExternalLinks from './ExternalLinks';

export const getIntlAppLabels = (intl: IntlShape): AppLabels => {
    const intlGet = (id: string): string => {
        return intl.formatMessage({ id: id });
    };
    return {
        intlGet,
        common: {
            actions: intlGet('common_actions'),
            description: intlGet('common_description'),
            organization_name: intlGet('common_organization_name'),
            info: intlGet('common_info'),
            unknown: intlGet('common_unknown'),
            total: intlGet('common_total'),
            percentage: intlGet('common_percentage'),
            count: intlGet('common_count'),
            email: intlGet('common_email'),
            emailOrUserName: intlGet('common_email_or_username'),
            legend: intlGet('common_legend'),
            date_format_constraint_text: intlGet('common_date_format_constraint_text'),
            selected_date: intlGet('common_selected_date'),
            next_month: intlGet('common_next_month'),
            date_placeholder: intlGet('common_date_placeholder'),
            preferences_title: intlGet('common_preferences_title'),
            page_size_preference_title: intlGet('common_page_size_preference_title'),
            options: intlGet('common_options'),
            yes: intlGet('common_yes'),
            no: intlGet('common_no'),
            i_dont_know: intlGet('common_i_dont_know'),
            optional: intlGet('common_optional'),
            error: intlGet('common_error'),
            step: intlGet('common_step'),
            and: intlGet('common_and'),
            length_must_be_between: intlGet('common_length_must_be_between'),
            high: intlGet('common_high'),
            medium: intlGet('common_medium'),
            low: intlGet('common_low'),
            long: intlGet('common_long'),
            short: intlGet('common_short'),
            na: intlGet('common_na'),
            partners: intlGet('common_partners'),
        },
        user_actions: {
            action: intlGet('user_actions_action'),
            create: intlGet('user_actions_create'),
            cancel: intlGet('user_actions_cancel'),
            apply: intlGet('user_actions_apply'),
            confirm: intlGet('user_actions_confirm'),
            close: intlGet('user_actions_close'),
            close_dialog: intlGet('user_actions_close_dialog'),
            close_alert: intlGet('user_actions_close_alert'),
            send: intlGet('user_actions_send'),
            save: intlGet('user_actions_save'),
            selected: intlGet('user_actions_selected'),
            search: intlGet('user_actions_search'),
            dismiss: intlGet('user_actions_dismiss'),
            edit: intlGet('user_actions_edit'),
            edit_settings: intlGet('user_actions_edit_settings'),
            share: intlGet('user_actions_share'),
            delete: intlGet('user_actions_delete'),
            drag: intlGet('user_actions_drag'),
            retry_update: intlGet('user_actions_retry_update'),
            previous: intlGet('user_actions_previous'),
            back: intlGet('user_actions_back'),
            next: intlGet('user_actions_next'),
            submit: intlGet('user_actions_submit'),
            preview: intlGet('user_actions_preview'),
            publish: intlGet('user_actions_publish'),
            reset: intlGet('user_actions_reset'),
            add: intlGet('user_actions_add'),
        },
        statuses: {
            saved: intlGet('statuses_saved'),
            saving: intlGet('statuses_saving'),
            failed_to_save_refresh: intlGet('statuses_failed_to_save_refresh'),
        },
        features: {
            error_loading_features: intlGet('features_error_loading_features'),
        },
        header: {
            feedback: {
                title: intlGet('header_feedback_title'),
            },
            resources: {
                title: intlGet('header_resources_title'),
            },
        },
        side_navigation: {
            administration: {
                administration: intlGet('side_navigation_administration_administration'),
                customer_accounts: intlGet('side_navigation_administration_customer_accounts'),
                overview: intlGet('side_navigation_administration_overview'),
                recommended_actions: intlGet('side_navigation_administration_recommended_actions'),
                report_templates: intlGet('side_navigation_administration_report_templates'),
                manage_templates: intlGet('side_navigation_administration_manage_templates'),
            },
            view_all_assessments: intlGet('side_navigation_view_all_assessments'),
            pre_event: {
                pre_event: intlGet('side_navigation_pre_event_pre_event'),
                manage_sponsor: intlGet('side_navigation_pre_event_manage_sponsor'),
            },
            assessment: intlGet('side_navigation_assessment'),
            results: {
                results: intlGet('side_navigation_results_results'),
                update_assessment_info: intlGet('side_navigation_results_update_assessment_info'),
                review_all: intlGet('side_navigation_results_review_all'),
                generate_report: intlGet('side_navigation_results_generate_report'),
                complete_assessment: intlGet('side_navigation_results_complete_assessment'),
            },
            missing_name: intlGet('side_navigation_missing_name'),
        },
        landing: {
            title: intlGet('landing_title'),
            serviceName: intlGet('landing_title'),
            majorDescription: intlGet('landing_majorDescription'),
            minorDescription: intlGet('landing_minorDescription'),
            whatItDoes: {
                title: intlGet('landing_whatItDoes_title'),
                description: intlGet('landing_whatItDoes_description'),
            },
            gettingStarted: {
                title: intlGet('landing_gettingStarted_title'),
                links: [
                    {
                        linkText: intlGet('landing_gettingStarted_links_1_linkText'),
                        linkLocation: intlGet('landing_gettingStarted_links_1_linkLocation'),
                    },
                ],
                pulldown_header: intlGet('landing_gettingStarted_pulldown_header'),
                pulldown_footer: intlGet('landing_gettingStarted_pulldown_footer'),
            },
            benefitsAndFeatures: {
                title: intlGet('landing_benefitsAndFeatures_title'),
                items: [
                    {
                        title: intlGet('landing_benefitsAndFeatures_1_title'),
                        description: intlGet('landing_benefitsAndFeatures_1_description'),
                    },
                    {
                        title: intlGet('landing_benefitsAndFeatures_2_title'),
                        description: intlGet('landing_benefitsAndFeatures_2_description'),
                    },
                    {
                        title: intlGet('landing_benefitsAndFeatures_3_title'),
                        description: intlGet('landing_benefitsAndFeatures_3_description'),
                    },
                ],
            },
            moreResources: {
                title: intlGet('landing_moreResources_title'),
                // These items are referenced by header dropdowns using items index.
                // Please make sure indexes are updated in header dropdowns when adding/removing items.
                items: [
                    {
                        linkLocation: ExternalLinks.APG_MRA,
                        linkText: intlGet('landing_moreResources_2_linkText'),
                    },
                    {
                        linkLocation: ExternalLinks.APG_MRA_FAQ,
                        linkText: intlGet('landing_moreResources_3_linkText'),
                    },
                    {
                        linkLocation: ExternalLinks.A2T_SLACK_CHANNEL,
                        linkText: intlGet('landing_moreResources_6_linkText'),
                    },
                ],
            },
            relatedServices: {
                title: intlGet('landing_relatedServices_title'),
                items: [
                    {
                        title: intlGet('landing_relatedServices_1_title'),
                        description: intlGet('landing_relatedServices_1_description'),
                        serviceLinkLocation: ExternalLinks.MPA,
                    },
                    {
                        title: intlGet('landing_relatedServices_2_title'),
                        description: intlGet('landing_relatedServices_2_description'),
                        serviceLinkLocation: ExternalLinks.APG,
                    },
                ],
            },
        },
        customer_selection: {
            table_title: intlGet('customer_selection_table_title'),
            filter_space_holder: intlGet('customer_selection_filter_space_holder'),
            create_customer: intlGet('customer_selection_create_customer'),
            customer_account_name: intlGet('customer_selection_customer_account_name'),
            customer_account_id: intlGet('customer_selection_customer_account_id'),
            customer_industry: intlGet('customer_selection_customer_industry'),
            customer_region: intlGet('customer_selection_customer_region'),
            customer_territory: intlGet('customer_selection_customer_territory'),
            customer_website: intlGet('customer_selection_customer_website'),
            customers: intlGet('customer_selection_customers'),
            reference_id: intlGet('customer_selection_reference_id'),
            created_by: intlGet('customer_selection_created_by'),
            action: intlGet('customer_selection_action'),
            action_select: intlGet('customer_selection_action_select'),
            action_select_all_customers: intlGet('customer_selection_action_select_all_customers'),
            all_customers: intlGet('customer_selection_all_customers'),
            all_customers_selected: intlGet('customer_selection_all_customers_selected'),
            selected_customer: intlGet('customer_selection_selected_customer'),
            see_more_customers: intlGet('customer_selection_see_more_customers'),
            loading_customers: intlGet('customer_selection_loading_customers'),
            preference_title: intlGet('customer_selection_preference_title'),
            page_size_preference_title: intlGet('customer_selection_page_size_preference_title'),
            table_no_customers: intlGet('customer_selection_table_no_customers'),
            table_no_customers_to_display: intlGet('customer_selection_table_no_customers_to_display'),
            error_loading_customers: intlGet('customer_selection_table_error_loading_customers'),
            error_invalid_customer_id: intlGet('customer_selection_table_error_invalid_customer_id'),
            create: {
                title: intlGet('customer_selection_create_title'),
                account_details: intlGet('customer_selection_create_account_details'),
                account_name_placeholder: intlGet('customer_selection_create_account_name_placeholder'),
                reference_id_placeholder: intlGet('customer_selection_create_reference_id_placeholder'),
                create_customer_account_successful: intlGet('customer_selection_create_create_customer_account_successful'),
                error_creating_customer: intlGet('customer_selection_create_error_creating_customer'),
            },
            update: {
                title: intlGet('customer_selection_update_title'),
                update_customer_account_successful: intlGet('customer_selection_update_update_customer_account_successful'),
                error_updating_customer: intlGet('customer_selection_update_error_updating_customer'),
            },
        },
        assessment: {
            current_assessment: intlGet('assessment_current_assessment'),
            description: intlGet('assessment_description'),
            customer_account_name: intlGet('assessment_customer_account_name'),
            type: intlGet('assessment_type'),
            status: intlGet('assessment_status'),
            created_by: intlGet('assessment_created_by'),
            last_updated: intlGet('assessment_last_updated'),
            workstreams: intlGet('assessment_workstreams'),
            perspectives: intlGet('assessment_perspectives'),
            status_bar: {
                answered: intlGet('assessment_status_bar_answered'),
                in_progress: intlGet('assessment_status_bar_in_progress'),
                completed: intlGet('assessment_status_bar_completed'),
                of: intlGet('assessment_status_bar_of'),
                current_prompt_number: intlGet('assessment_status_bar_current_prompt_number'),
            },
            create: {
                template_table_empty_title: intlGet('assessment_create_template_table_empty_title'),
                template_table_header: intlGet('assessment_create_template_table_header'),
                enter_assessment_details: intlGet('assessment_create_enter_assessment_details'),
                review_assessment_details: intlGet('assessment_create_review_assessment_details'),
                invalid_assessment_details: intlGet('assessment_create_invalid_assessment_details'),
                error_creating_assessment: intlGet('assessment_create_error_creating_assessment'),
                errors_retrieving_templates: intlGet('assessment_create_errors_retrieving_templates'),
                error_retrieving_all_templates: intlGet('assessment_create_error_retrieving_all_templates'),
                error_getting_assessment_types: intlGet('assessment_create_error_getting_assessment_types'),
                customer_accounts: intlGet('assessment_create_customer_accounts'),
                customer_account: intlGet('assessment_create_customer_account'),
                account_id: intlGet('assessment_create_account_id'),
                owner: intlGet('assessment_create_owner'),
                territory: intlGet('assessment_create_territory'),
                load_customers_error_message: intlGet('assessment_create_load_customers_error_message'),
                error_retrieving_all_customer_accounts: intlGet('assessment_create_error_retrieving_all_customer_accounts'),
                error_getting_customer_accounts: intlGet('assessment_create_error_getting_customer_accounts'),
                loading_customers: intlGet('assessment_create_loading_customers'),
                customer_accounts_found: intlGet('assessment_create_customer_accounts_found'),
                no_customer_accounts: intlGet('assessment_create_no_customer_accounts'),
                no_customer_accounts_to_display: intlGet('assessment_create_no_customer_accounts_to_display'),
                reload_customer_accounts: intlGet('assessment_create_reload_customer_accounts'),
                details: intlGet('assessment_create_details'),
                details_account: intlGet('assessment_create_details_account'),
                details_description: intlGet('assessment_create_details_description'),
                details_description_placeholder: intlGet('assessment_create_details_description_placeholder'),
                map_id: intlGet('assessment_create_map_id'),
                map_id_placeholder: intlGet('assessment_create_map_id_placeholder'),
                customer_contact: intlGet('assessment_create_customer_contact'),
                customer_contact_placeholder: intlGet('assessment_create_customer_contact_placeholder'),
                workshop_date: intlGet('assessment_create_workshop_date'),
                readout_date: intlGet('assessment_create_readout_date'),
                previous_month: intlGet('assessment_create_previous_month'),
                today: intlGet('assessment_create_today'),
                delivered_by: intlGet('assessment_create_delivered_by'),
                demo_or_test_constraint: intlGet('assessment_create_demo_or_test_constraint'),
                demo_or_test: intlGet('assessment_create_demo_or_test'),
                help_panel_delivery_guidance: intlGet('assessment_create_help_panel_delivery_guidance'),
                info_panel_step_1: intlGet('assessment_create_info_panel_step_1'),
                workshopdate_readoutdate_constraint: intlGet('assessment_create_workshopdate_readoutdate_constraint'),
                optout_recommendation_constraint: intlGet('assessment_create_optout_recommendation_constraint'),
                optout_recommendation: intlGet('assessment_create_optout_recommendation'),
                select_customer: {
                    title: intlGet('assessment_create_select_customer_title'),
                    description: intlGet('assessment_create_select_customer_description'),
                    description_partner: intlGet('assessment_create_select_customer_description_partner'),
                    filter_placeholder: intlGet('assessment_create_select_customer_filter_placeholder'),
                    no_account_selected: intlGet('assessment_create_select_customer_no_account_selected'),
                },
                customer_profile: {
                    title: intlGet('assessment_create_customer_profile_title'),
                    header: intlGet('assessment_create_customer_profile_header'),
                    description: intlGet('assessment_create_customer_profile_description'),
                    segments: {
                        prompt: intlGet('assessment_create_customer_profile_segment_prompt'),
                        description: intlGet('assessment_create_customer_profile_segment_description'),
                        ent: {
                            label: intlGet('assessment_create_customer_profile_segments_ent_label'),
                            description: intlGet('assessment_create_customer_profile_segments_ent_description'),
                        },
                        dnb: {
                            label: intlGet('assessment_create_customer_profile_segments_dnb_label'),
                            description: intlGet('assessment_create_customer_profile_segments_dnb_description'),
                        },
                        isv: {
                            label: intlGet('assessment_create_customer_profile_segments_isv_label'),
                            description: intlGet('assessment_create_customer_profile_segments_isv_description'),
                        },
                        smb: {
                            label: intlGet('assessment_create_customer_profile_segments_smb_label'),
                            description: intlGet('assessment_create_customer_profile_segments_smb_description'),
                        },
                        sup: {
                            label: intlGet('assessment_create_customer_profile_segments_sup_label'),
                            description: intlGet('assessment_create_customer_profile_segments_sup_description'),
                        },
                    },
                    customer_cloud_maturity: {
                        prompt: intlGet('assessment_create_customer_profile_cloud_maturity_prompt'),
                        description: intlGet('assessment_create_customer_profile_cloud_maturity_description'),
                        low: {
                            label: intlGet('assessment_create_customer_profile_cloud_maturity_low_label'),
                            description: intlGet('assessment_create_customer_profile_cloud_maturity_low_description'),
                        },
                        high: {
                            label: intlGet('assessment_create_customer_profile_cloud_maturity_high_label'),
                            description: intlGet('assessment_create_customer_profile_cloud_maturity_high_description'),
                        },
                    },
                    customer_plan_and_alignment_exists: {
                        prompt: intlGet('assessment_create_customer_profile_plan_and_alignment_exists_prompt'),
                        description: intlGet('assessment_create_customer_profile_plan_and_alignment_exists_description'),
                        yes: {
                            label: intlGet('assessment_create_customer_profile_plan_and_alignment_exists_yes_label'),
                            description: intlGet('assessment_create_customer_profile_plan_and_alignment_exists_yes_description'),
                        },
                        no: {
                            label: intlGet('assessment_create_customer_profile_plan_and_alignment_exists_no_label'),
                            description: intlGet('assessment_create_customer_profile_plan_and_alignment_exists_no_description'),
                        },
                    },
                },
                select_assessment_type: {
                    title: intlGet('assessment_create_select_assessment_type_title'),
                    description: intlGet('assessment_create_select_assessment_type_description'),
                    filter_placeholder: intlGet('assessment_create_select_assessment_type_filter_placeholder'),
                },
                recommendation_alerts: {
                    mra_not_recommended: intlGet('assessment_create_recommendation_alerts_mra_not_recommended'),
                },
            },
            list: {
                all_customers: intlGet('assessment_list_all_customers'),
                create_assessments: intlGet('assessment_list_create_assessments'),
                active_and_favorite_assessments: intlGet('assessment_list_active_and_favorite_assessments'),
                completed_assessments: intlGet('assessment_list_completed_assessments'),
                assessments: intlGet('assessment_list_assessments'),
                filter_placeholder: intlGet('assessment_list_filter_placeholder'),
                loading_assessments: intlGet('assessment_list_loading_assessments'),
                preference_title: intlGet('assessment_list_preference_title'),
                page_size_preference_title: intlGet('assessment_list_page_size_preference_title'),
                assessments_table_no_assessments: intlGet('assessment_list_assessments_table_no_assessments'),
                assessments_table_no_assessments_to_display: intlGet('assessment_list_assessments_table_no_assessments_to_display'),
                workshop_date: intlGet('assessment_list_workshop_date'),
                readout_date: intlGet('assessment_list_readout_date'),
                actions: intlGet('assessment_list_actions'),
                info_panel_text1: intlGet('assessment_list_info_panel_text1'),
                info_panel_text2: intlGet('assessment_list_info_panel_text2'),
                info_panel_text3: intlGet('assessment_list_info_panel_text3'),
            },
            share: {
                share: intlGet('assessment_share_share'),
                user_not_found: intlGet('assessment_share_user_not_found'),
                multiple_users_found: intlGet('assessment_share_multiple_users_found'),
                select_users: intlGet('assessment_share_select_users'),
                invite: intlGet('assessment_share_invite'),
                invite_message: intlGet('assessment_share_invite_message'),
                accept_invitation: intlGet('assessment_share_accept_invitation'),
                accept_invitation_message: intlGet('assessment_share_accept_invitation_message'),
                accept_invitation_successful: intlGet('assessment_share_accept_invitation_successful'),
                current_permissions: intlGet('assessment_share_current_permissions'),
                share_this_assessment: intlGet('assessment_share_share_this_assessment'),
                permission: intlGet('assessment_share_permission'),
                username: intlGet('assessment_share_username'),
                username_placeholder: intlGet('assessment_share_username_placeholder'),
                name: intlGet('assessment_share_name'),
                remove: intlGet('assessment_share_remove'),
                status: intlGet('assessment_share_status'),
                action_add: intlGet('assessment_share_action_add'),
                status_pending_add: intlGet('assessment_share_status_pending_add'),
                status_pending_update: intlGet('assessment_share_status_pending_update'),
                status_pending_invite: intlGet('assessment_share_status_pending_invite'),
                status_invited: intlGet('assessment_share_status_invited'),
                status_saved: intlGet('assessment_share_status_saved'),
                loading_permissions: intlGet('assessment_share_loading_permissions'),
                table_no_permissions: intlGet('assessment_share_table_no_permissions'),
                table_no_permissions_to_display: intlGet('assessment_share_table_no_permissions_to_display'),
                without_at_sign: intlGet('assessment_share_without_at_sign'),
                error_update_permissions: intlGet('assessment_share_error_update_permissions'),
                error_remove_permissions: intlGet('assessment_share_error_remove_permissions'),
                error_remove_last_permission: intlGet('assessment_share_error_remove_last_permission'),
                error_invalid_username: intlGet('assessment_share_error_invalid_username'),
                error_accept_invitation: intlGet('assessment_share_error_accept_invitation'),
            },
            workshop_filters: {
                answered: intlGet('assessment_workshop_filters_answered'),
                header: intlGet('assessment_workshop_filters_header'),
                live_event: intlGet('assessment_workshop_filters_live_event'),
                polling_results_description: intlGet('assessment_workshop_filters_polling_results_description'),
                polling_results_header: intlGet('assessment_workshop_filters_polling_results_header'),
                pre_event: intlGet('assessment_workshop_filters_pre_event'),
                questions_description: intlGet('assessment_workshop_filters_questions_description'),
                questions_header: intlGet('assessment_workshop_filters_questions_header'),
                unanswered: intlGet('assessment_workshop_filters_unanswered'),
            },
            pre_event: {
                manage_sponsor: intlGet('assessment_pre_event_manage_sponsor'),
                sponsor: intlGet('assessment_pre_event_sponsor'),
                email_placeholder: intlGet('assessment_pre_event_email_placeholder'),
                due_date: intlGet('assessment_pre_event_due_date'),
                due_date_description: intlGet('assessment_pre_event_due_date_description'),
                previous_month: intlGet('assessment_pre_event_previous_month'),
                today: intlGet('assessment_pre_event_today'),
                status: intlGet('assessment_pre_event_status'),
                participants: intlGet('assessment_pre_event_participants'),
                submitted_responses: intlGet('assessment_pre_event_submitted_responses'),
                pre_event_not_created: intlGet('assessment_pre_event_pre_event_not_created'),
                sponsor_email_validation_message: intlGet('assessment_pre_event_sponsor_email_validation_message'),
                due_date_validation_message: intlGet('assessment_pre_event_due_date_validation_message'),
                couldnt_share: intlGet('assessment_pre_event_couldnt_share'),
                error_saving: intlGet('assessment_pre_event_error_saving'),
                assessment_not_loaded: intlGet('assessment_pre_event_assessment_not_loaded'),
                error_loading: intlGet('assessment_pre_event_error_loading'),
                cannot_update_unsaved: intlGet('assessment_pre_event_cannot_update_unsaved'),
                sponsor_not_save: intlGet('assessment_pre_event_sponsor_not_save'),
                cannot_send_email: intlGet('assessment_pre_event_cannot_send_email'),
                email_template_not_available: intlGet('assessment_pre_event_email_template_not_available'),
                info_panel_text_1: intlGet('assessment_pre_event_info_panel_text_1'),
                info_panel_text_2: intlGet('assessment_pre_event_info_panel_text_2'),
                info_panel_text_3: intlGet('assessment_pre_event_info_panel_text_3'),
                questionnaire_sponsor_view: intlGet('assessment_pre_event_questionnaire_sponsor_view'),
                questionnaire_participant_view: intlGet('assessment_pre_event_questionnaire_participant_view'),
                send_designated_questions: intlGet('assessment_pre_event_send_designated_questions'),
                send_all_questions: intlGet('assessment_pre_event_send_all_questions'),
                send_all_questions_description: intlGet('assessment_pre_event_send_all_questions_description'),
            },
            facilitate: {
                filter: intlGet('assessment_facilitate_filter'),
                next: intlGet('assessment_facilitate_next'),
                previous: intlGet('assessment_facilitate_previous'),
                comments: intlGet('assessment_facilitate_comments'),
                comments_placeholder: intlGet('assessment_facilitate_comments_placeholder'),
                comments_saved: intlGet('assessment_facilitate_comments_saved'),
                error_api: intlGet('assessment_facilitate_error_api'),
                live_event: intlGet('assessment_facilitate_live_event'),
                participant_comments: intlGet('assessment_facilitate_participant_comments'),
                i_dont_know: intlGet('assessment_facilitate_i_dont_know'),
                pre_event: intlGet('assessment_facilitate_pre_event'),
                rating_saved: intlGet('assessment_facilitate_rating_saved'),
                response_saved: intlGet('assessment_facilitate_response_saved'),
                try_update_again: intlGet('assessment_facilitate_try_update_again'),
                updating_comments: intlGet('assessment_facilitate_updating_comments'),
                updating_rating: intlGet('assessment_facilitate_updating_rating'),
                updating_response: intlGet('assessment_facilitate_updating_response'),
                votes: intlGet('assessment_facilitate_votes'),
                error_loading_question: intlGet('assessment_facilitate_error_loading_question'),
                error_loading_assessment: intlGet('assessment_facilitate_error_loading_assessment'),
                category_has_no_questions: intlGet('assessment_facilitate_category_has_no_questions'),
            },
            live_session: {
                start: intlGet('assessment_live_session_start'),
                active: intlGet('assessment_live_session_active'),
                create_new_session: intlGet('assessment_live_session_create_new_session'),
                create_password_label: intlGet('assessment_live_session_create_password_label'),
                create_password_description: intlGet('assessment_live_session_create_password_description'),
                create_password_hint: intlGet('assessment_live_session_create_password_hint'),
                create_password_placeholder: intlGet('assessment_live_session_create_password_placeholder'),
                create_session_instruction: intlGet('assessment_live_session_create_session_instruction'),
                create_session_title_label: intlGet('assessment_live_session_create_session_title_label'),
                create_session_title_placeholder: intlGet('assessment_live_session_create_session_title_placeholder'),
                create_session_title_hint: intlGet('assessment_live_session_create_session_title_hint'),
                error_api: intlGet('assessment_live_session_error_api'),
                inactive: intlGet('assessment_live_session_inactive'),
                resume_session: intlGet('assessment_live_session_resume_session'),
                session_link: intlGet('assessment_live_session_session_link'),
                start_polling_session: intlGet('assessment_live_session_start_polling_session'),
                suspend_session: intlGet('assessment_live_session_suspend_session'),
            },
            update: {
                cancel_button_text: intlGet('assessment_update_cancel_button_text'),
                error: intlGet('assessment_update_update_assessment_error'),
                error_updating_assessment: intlGet('assessment_update_update_assessment_error_updating'),
                update_assessment_successful: intlGet('assessment_update_update_assessment_successful'),
                invalid_assessment_details: intlGet('assessment_update_update_assessment_invalid_assessment_details'),
                load_assessment_error_content: intlGet('assessment_update_load_assessment_error_content'),
                load_assessment_error_header: intlGet('assessment_update_load_assessment_error_header'),
                load_assessment_errors_content: intlGet('assessment_update_load_assessment_errors_content'),
                load_assessment_errors_header: intlGet('assessment_update_load_assessment_errors_header'),
                review_assessment_details: intlGet('assessment_update_update_assment_review_details'),
                save_button_text: intlGet('assessment_update_save_button_text'),
                title: intlGet('assessment_update_title'),
                info_panel_text: intlGet('assessment_update_info_panel_text'),
            },
            delete: {
                delete: intlGet('assessment_delete_delete'),
                delete_this_assessment_permanently: intlGet('assessment_delete_delete_this_assessment_permanently'),
                confirm_prompt: intlGet('assessment_delete_confirm_prompt'),
                confirm_input_expected: intlGet('assessment_delete_confirm_input_expected'),
                error_delete_assessment: intlGet('assessment_delete_error_delete_assessment'),
            },
            complete: {
                title: intlGet('assessment_complete_title'),
                view_report: intlGet('assessment_complete_view_report'),
                complete_button_text: intlGet('assessment_complete_complete_button_text'),
                update_button_text: intlGet('assessment_complete_update_button_text'),
                info_panel_text: intlGet('assessment_complete_info_panel_text'),
                status_constraint: intlGet('assessment_complete_status_constraint'),
                error_completing_assessment: intlGet('assessment_complete_error_completing_assessment'),
                complete_assessment_successful: intlGet('assessment_complete_complete_assessment_successful'),
            },
            model: {
                account_is_not_empty: intlGet('assessment_model_account_is_not_empty'),
                description_is_not_empty: intlGet('assessment_model_description_is_not_empty'),
                map_id_is_not_empty: intlGet('assessment_model_map_id_is_not_empty'),
                workshop_date_is_not_empty: intlGet('assessment_model_workshop_date_is_not_empty'),
                readout_date_is_not_empty: intlGet('assessment_model_readout_date_is_not_empty'),
                delivered_by_is_not_empty: intlGet('assessment_model_delivered_by_is_not_empty'),
                type_is_not_empty: intlGet('assessment_model_type_is_not_empty'),
            },
            results: {
                default_rating_bands: {
                    title_incomplete: intlGet('assessment_default_rating_band_title_incomplete'),
                    title_bad: intlGet('assessment_default_rating_band_title_bad'),
                    title_concern: intlGet('assessment_default_rating_band_title_concern'),
                    title_good: intlGet('assessment_default_rating_band_title_good'),
                    description_incomplete: intlGet('assessment_default_rating_band_description_incomplete'),
                    description_bad: intlGet('assessment_default_rating_band_description_bad'),
                    description_concern: intlGet('assessment_default_rating_band_description_concern'),
                    description_good: intlGet('assessment_default_rating_band_description_good'),
                },
                review_all: {
                    legend_incomplete: intlGet('assessment_results_review_all_legend_incomplete'),
                    legend_concern: intlGet('assessment_results_review_all_legend_concern'),
                    legend_good: intlGet('assessment_results_review_all_legend_good'),
                    legend_bad: intlGet('assessment_results_review_all_legend_bad'),
                    design_evolve: intlGet('assessment_results_review_all_design_evolve'),
                    heatmap: {
                        all_drivers: intlGet('assessment_results_review_all_heatmap_all_drivers'),
                        heatmap: intlGet('assessment_results_review_all_heatmap_heatmap'),
                        legends: intlGet('assessment_results_review_all_heatmap_legends'),
                        info_panel_text: intlGet('assessment_results_review_all_heatmap_info_panel_text'),
                        status_text_incomplete: intlGet('assessment_results_review_all_heatmap_status_text_incomplete'),
                        status_text_good: intlGet('assessment_results_review_all_heatmap_status_text_good'),
                        status_text_concern: intlGet('assessment_results_review_all_heatmap_status_text_concern'),
                        status_text_bad: intlGet('assessment_results_review_all_heatmap_status_text_bad'),
                        pre_mobilize: intlGet('assessment_results_review_all_heatmap_pre_mobilize'),
                    },
                    radar: {
                        radar: intlGet('assessment_results_review_all_radar_radar'),
                        info_panel_text: intlGet('assessment_results_review_all_radar_info_panel_text'),
                    },
                    scores: {
                        scores: intlGet('assessment_results_review_all_scores_scores'),
                        na: intlGet('assessment_results_review_all_scores_na'),
                        not_applicable: intlGet('assessment_results_review_all_scores_not_applicable'),
                        incomplete: intlGet('assessment_results_review_all_scores_incomplete'),
                        info_panel_text: intlGet('assessment_results_review_all_scores_info_panel_text'),
                    },
                    responseDistribution: {
                        responseDistribution: intlGet('assessment_results_review_all_response_distribution'),
                        info_panel_text: intlGet('assessment_results_review_all_response_distribution_info_panel_text'),
                    },
                    report: {
                        report: intlGet('assessment_results_review_all_report_report'),
                        info_header: intlGet('assessment_results_review_all_report_info_header'),
                        info_legend: intlGet('assessment_results_review_all_report_info_legend'),
                        info_content: intlGet('assessment_results_review_all_report_info_content'),
                        questions: intlGet('assessment_results_review_all_report_questions'),
                        remove: intlGet('assessment_results_review_all_report_remove'),
                        filtering_text_placeholder: intlGet('assessment_results_review_all_report_filtering_text_placeholder'),
                        choose_rating: intlGet('assessment_results_review_all_report_choose_rating'),
                        choose_category: intlGet('assessment_results_review_all_report_choose_category'),
                        choose_activity: intlGet('assessment_results_review_all_report_choose_activity'),
                        choose_phase: intlGet('assessment_results_review_all_report_choose_phase'),
                        text: intlGet('assessment_results_review_all_report_text'),
                        rating: intlGet('assessment_results_review_all_report_rating'),
                        category: intlGet('assessment_results_review_all_report_category'),
                        activity: intlGet('assessment_results_review_all_report_activity'),
                        phase: intlGet('assessment_results_review_all_report_phase'),
                        facilitator_comments: intlGet('assessment_results_review_all_report_facilitator_comments'),
                        facilitator_comments_error_api: intlGet('assessment_results_review_all_report_facilitator_comments_error_api'),
                        facilitator_comments_placeholder: intlGet('assessment_results_review_all_report_facilitator_comments_placeholder'),
                        facilitator_comments_saved: intlGet('assessment_results_review_all_report_facilitator_comments_saved'),
                        facilitator_comments_try_update_again: intlGet('assessment_results_review_all_report_facilitator_comments_try_update_again'),
                        facilitator_comments_updating: intlGet('assessment_results_review_all_report_facilitator_comments_updating'),
                        participant_comments: intlGet('assessment_results_review_all_report_participant_comments'),
                        summary_observations: intlGet('assessment_results_review_all_report_summary_observations'),
                        summary_observations_placeholder: intlGet('assessment_results_review_all_report_summary_observations_placeholder'),
                        summary_observations_tip: intlGet('assessment_results_review_all_report_summary_observations_tip'),
                        summary_observations_populate_hover_text: intlGet(
                            'assessment_result_review_all_report_summary_observations_populate_hover_text'
                        ),
                        pre_populate_summary_observations: intlGet('assessment_results_review_all_report_pre_populate_summary_observations'),
                        limit_show_more: intlGet('assessment_results_review_all_report_limit_show_more'),
                        limit_show_fewer: intlGet('assessment_results_review_all_report_limit_show_fewer'),
                        recommended_actions: {
                            recommended_actions: intlGet('assessment_results_review_all_report_recommended_actions_recommended_actions'),
                            delete_recommendations: intlGet('assessment_results_review_all_report_recommended_actions_delete_recommendations'),
                            confirm_delete_recommendations: intlGet(
                                'assessment_results_review_all_report_recommended_actions_confirm_delete_recommendations'
                            ),
                            create_custom_recommendation: intlGet(
                                'assessment_results_review_all_report_recommended_actions_create_custom_recommendation'
                            ),
                            edit_recommendation: intlGet('assessment_results_review_all_report_recommended_actions_edit_recommendation'),
                            select_prescribed_recommendations: intlGet(
                                'assessment_results_review_all_report_recommended_actions_select_prescribed_recommendations'
                            ),
                            failed_delete_recommendations: intlGet(
                                'assessment_results_review_all_report_recommended_actions_failed_delete_recommendations'
                            ),
                            failed_select_prescribed_recommendations: intlGet(
                                'assessment_results_review_all_report_recommended_actions_failed_select_prescribed_recommendations'
                            ),
                            failed_create_custom_recommendation: intlGet(
                                'assessment_results_review_all_report_recommended_actions_failed_create_custom_recommendation'
                            ),
                            failed_edit_recommendation: intlGet(
                                'assessment_results_review_all_report_recommended_actions_failed_edit_recommendation'
                            ),
                            failed_load_recommendations: intlGet(
                                'assessment_results_review_all_report_recommended_actions_failed_load_recommendations'
                            ),
                            in_progress: intlGet('assessment_results_review_all_report_recommended_actions_in_progress'),
                            inline_edit_disabled: intlGet('assessment_results_review_all_report_recommended_actions_inline_edit_disabled'),
                            completed: intlGet('assessment_results_review_all_report_recommended_actions_completed'),
                            not_started: intlGet('assessment_results_review_all_report_recommended_actions_not_started'),
                            add_modal: {
                                header: intlGet('assessment_results_review_all_report_recommended_actions_add_modal_header'),
                                header_2: intlGet('assessment_results_review_all_report_recommended_actions_add_modal_header_2'),
                                button_cancel: intlGet('assessment_results_review_all_report_recommended_actions_add_modal_button_cancel'),
                                button_custom_action: intlGet(
                                    'assessment_results_review_all_report_recommended_actions_add_modal_button_custom_action'
                                ),
                                button_ok: intlGet('assessment_results_review_all_report_recommended_actions_add_modal_button_ok'),
                            },
                            grid: {
                                button_add_action: intlGet('assessment_results_review_all_report_recommended_actions_grid_button_add_action'),
                                button_add_custom_action: intlGet(
                                    'assessment_results_review_all_report_recommended_actions_grid_button_add_custom_action'
                                ),
                                button_add_prescribed_action: intlGet(
                                    'assessment_results_review_all_report_recommended_actions_grid_button_add_prescribed_action'
                                ),
                                column_action: intlGet('assessment_results_review_all_report_recommended_actions_grid_column_action'),
                                column_owner: intlGet('assessment_results_review_all_report_recommended_actions_grid_column_owner'),
                                column_recommendation_owner: intlGet(
                                    'assessment_results_review_all_report_recommended_actions_grid_column_recommendation_owner'
                                ),
                                column_action_owner: intlGet('assessment_results_review_all_report_recommended_actions_grid_column_action_owner'),
                                column_priority: intlGet('assessment_results_review_all_report_recommended_actions_grid_column_priority'),
                                column_effort: intlGet('assessment_results_review_all_report_recommended_actions_grid_column_effort'),
                                column_target_date: intlGet('assessment_results_review_all_report_recommended_actions_grid_column_target_date'),
                                column_progress: intlGet('assessment_results_review_all_report_recommended_actions_grid_column_progress'),
                                column_benefit: intlGet('assessment_results_review_all_report_recommended_actions_grid_column_benefit'),
                                column_delete: intlGet('assessment_results_review_all_report_recommended_action_grid_column_delete'),
                                column_drag: intlGet('assessment_results_review_all_report_recommended_action_grid_column_drag'),
                                header: intlGet('assessment_results_review_all_report_recommended_actions_grid_header'),
                                nodata_message: intlGet('assessment_results_review_all_report_recommended_actions_grid_nodata_message'),
                                placeholder_targetdate: intlGet(
                                    'assessment_results_review_all_report_recommended_actions_grid_placeholder_targetdate'
                                ),
                                placeholder_owner: intlGet('assessment_results_review_all_report_recommended_actions_grid_placeholder_owner'),
                                actions_saved: intlGet('assessment_results_review_all_report_recommended_actions_grid_actions_saved'),
                                error_api: intlGet('assessment_results_review_all_report_recommended_actions_grid_error_api'),
                                try_update_again: intlGet('assessment_results_review_all_report_recommended_actions_grid_try_update_again'),
                                updating_actions: intlGet('assessment_results_review_all_report_recommended_actions_grid_updating_actions'),
                            },
                        },
                        error_empty_report: intlGet('assessment_results_review_all_report_error_empty_report'),
                    },
                },
                manage_snapshots: {
                    info_panel_text: intlGet('assessment_results_manage_snapshots_info_panel_text'),
                },
                generate_report: {
                    generate_report: intlGet('assessment_results_generate_report_generate_report'),
                    results_presentation: intlGet('assessment_results_generate_report_results_presentation'),
                    powerpoint_slides: intlGet('assessment_results_generate_report_powerpoint_slides'),
                    assessment_report: intlGet('assessment_results_generate_report_assessment_report'),
                    word_document: intlGet('assessment_results_generate_report_word_document'),
                    recommended_action_backlog: intlGet('assessment_results_generate_report_recommended_action_backlog'),
                    excel_spreadsheet: intlGet('assessment_results_generate_report_excel_spreadsheet'),
                    download: intlGet('assessment_results_generate_report_download'),
                    info_panel_text: intlGet('assessment_results_generate_report_info_panel_text'),
                    report_template_lookup: intlGet('assessment_results_generate_report_report_template_lookup'),
                    error_report_template_lookup: intlGet('assessment_results_generate_report_error_report_template_lookup'),
                    report_download_progress_text: intlGet('assessment_results_generate_report_report_download_progress_text'),
                    report_download_complete_text: intlGet('assessment_results_generate_report_report_download_complete_text'),
                    report_template_not_available: intlGet('assessment_results_generate_report_report_template_not_available'),
                    error_report_download: intlGet('assessment_results_generate_report_error_report_download'),
                    error_assessment_scorecard: intlGet('assessment_results_generate_report_error_assessment_scorecard'),
                    parameters: {
                        workstream: intlGet('assessment_results_generate_report_parameters_workstream'),
                        workstream_phase: intlGet('assessment_results_generate_report_parameters_workstream_phase'),
                        workstreams: intlGet('assessment_results_generate_report_parameters_workstreams'),
                        average_workstream_score: intlGet('assessment_results_generate_report_parameters_average_workstream_score'),
                        section: intlGet('assessment_results_generate_report_parameters_section'),
                        perspective: intlGet('assessment_results_generate_report_parameters_perspective'),
                        perspectives: intlGet('assessment_results_generate_report_parameters_perspectives'),
                        readiness_activity: intlGet('assessment_results_generate_report_parameters_readiness_activity'),
                        category: intlGet('assessment_results_generate_report_parameters_category'),
                        recommended_actions: intlGet('assessment_results_generate_report_parameters_recommended_actions'),
                        action: intlGet('assessment_results_generate_report_parameters_action'),
                        actions: intlGet('assessment_results_generate_report_parameters_actions'),
                        actions_and_next_steps: intlGet('assessment_results_generate_report_parameters_actions_and_next_steps'),
                        action_priority: intlGet('assessment_results_generate_report_parameters_action_priority'),
                        action_effort: intlGet('assessment_results_generate_report_parameters_action_effort'),
                        action_status: intlGet('assessment_results_generate_report_parameters_action_status'),
                        target_completion_date: intlGet('assessment_results_generate_report_parameters_target_completion_date'),
                        benefit: intlGet('assessment_results_generate_report_parameters_benefit'),
                        owner: intlGet('assessment_results_generate_report_parameters_owner'),
                        target_date: intlGet('assessment_results_generate_report_parameters_target_date'),
                        priority: intlGet('assessment_results_generate_report_parameters_priority'),
                        effort: intlGet('assessment_results_generate_report_parameters_effort'),
                        observations: intlGet('assessment_results_generate_report_parameters_observations'),
                        summary_observation: intlGet('assessment_results_generate_report_parameters_summary_observation'),
                        summary_observations: intlGet('assessment_results_generate_report_parameters_summary_observations'),
                        question: intlGet('assessment_results_generate_report_parameters_question'),
                        question_number: intlGet('assessment_results_generate_report_parameters_question_number'),
                        response_type: intlGet('assessment_results_generate_report_parameters_response_type'),
                        rating_guide: intlGet('assessment_results_generate_report_parameters_rating_guide'),
                        participant: intlGet('assessment_results_generate_report_parameters_participant'),
                        comment: intlGet('assessment_results_generate_report_parameters_comment'),
                        comments: intlGet('assessment_results_generate_report_parameters_comments'),
                        polling_responses: intlGet('assessment_results_generate_report_parameters_polling_responses'),
                        vote: intlGet('assessment_results_generate_report_parameters_vote'),
                        role: intlGet('assessment_results_generate_report_parameters_role'),
                        response: intlGet('assessment_results_generate_report_parameters_response'),
                        rating: intlGet('assessment_results_generate_report_parameters_rating'),
                        average_score: intlGet('assessment_results_generate_report_parameters_average_score'),
                        perspective_high_explanation: intlGet('assessment_results_generate_report_parameters_perspective_high_explanation'),
                        workstream_high_explanation: intlGet('assessment_results_generate_report_parameters_workstream_high_explanation'),
                        perspective_low_explanation: intlGet('assessment_results_generate_report_parameters_perspective_low_explanation'),
                        workstream_low_explanation: intlGet('assessment_results_generate_report_parameters_workstream_low_explanation'),
                        perspective_second_low_explanation: intlGet(
                            'assessment_results_generate_report_parameters_perspective_second_low_explanation'
                        ),
                        workstream_second_low_explanation: intlGet('assessment_results_generate_report_parameters_workstream_second_low_explanation'),
                        response_type_number: intlGet('assessment_results_generate_report_parameters_response_type_number'),
                        response_type_rating: intlGet('assessment_results_generate_report_parameters_response_type_rating'),
                        response_type_yes_no: intlGet('assessment_results_generate_report_parameters_response_type_yes_no'),
                        response_type_text: intlGet('assessment_results_generate_report_parameters_response_type_text'),
                        response_type_date_value: intlGet('assessment_results_generate_report_parameters_response_type_date_value'),
                        response_type_single_selection: intlGet('assessment_results_generate_report_parameters_response_type_single_selection'),
                        response_type_multi_selection: intlGet('assessment_results_generate_report_parameters_response_type_multi_selection'),
                        score: intlGet('assessment_results_generate_report_parameters_score'),
                        scores: intlGet('assessment_results_generate_report_parameters_scores'),
                        low_ams_score: intlGet('assessment_results_generate_report_low_ams_score'),
                        medium_ams_score: intlGet('assessment_results_generate_report_medium_ams_score'),
                        high_ams_score: intlGet('assessment_results_generate_report_high_ams_score'),
                        facilitated_by: intlGet('assessment_results_generate_report_facilitated_by'),
                    },
                },
            },
            snapshot: {
                add_snapshot: intlGet('assessment_snapshot_add_snapshot'),
                compare_snapshots: intlGet('assessment_snapshot_compare_snapshots'),
                create_snapshot: intlGet('assessment_snapshot_create_snapshot'),
                create_snapshot_error: intlGet('assessment_snapshot_create_snapshot_error'),
                create_snapshot_error_same_name: intlGet('assessment_snapshot_create_snapshot_error_same_name'),
                create_snapshot_success: intlGet('assessment_snapshot_create_snapshot_success'),
                manage_snapshot: intlGet('assessment_snapshot_manage_snapshot'),
                snapshots: intlGet('assessment_snapshot_snapshots'),
                view_snapshot: intlGet('assessment_snapshot_view_snapshot'),
            },
        },
        org_overview: {
            overview: intlGet('org_overview_overview'),
            assessments: intlGet('org_overview_assessments'),
            page_size_preference_title: intlGet('org_overview_page_size_preference_title'),
            filter_placeholder: intlGet('org_overview_filter_placeholder'),
            no_assessments_created: intlGet('org_overview_no_assessments_created'),
            no_assessments_created_subtitle: intlGet('org_overview_no_assessments_created_subtitle'),
            no_assessments_found: intlGet('org_overview_no_assessments_found'),
            try_different_search: intlGet('org_overview_try_different_search'),
            help_panel_header: intlGet('org_overview_help_panel_header'),
            help_panel_content: intlGet('org_overview_help_panel_content'),
            loading_overview: intlGet('org_overview_loading_overview'),
            no_overview_found: intlGet('org_overview_no_overview_found'),
            error_loading_overview: intlGet('org_overview_error_loading_overview'),
        },
        report_customization: {
            loading_header_message: intlGet('report_customization_loading_header_message'),
            failed_to_load_header_message: intlGet('report_customization_failed_to_load_header_message'),
            help_panel_header: intlGet('report_customization_help_panel_header'),
            help_panel_content: intlGet('report_customization_help_panel_content'),
            reload: intlGet('report_customization_reload'),
            save: intlGet('report_customization_save'),
            failed_to_save_header_message: intlGet('report_customization_failed_to_save_header_message'),
            page_header: intlGet('report_customization_page_header'),
            organization_name_field: intlGet('report_customization_organization_name_field'),
            organization_name_placeholder: intlGet('report_customization_organization_name_placeholder'),
            logo_field: intlGet('report_customization_logo_field'),
            existing_logo_text: intlGet('report_customization_existing_logo_text'),
            upload: intlGet('report_customization_upload'),
            upload_constraint_text: intlGet('report_customization_upload_constraint_text'),
            upload_error_text_too_big: intlGet('report_customization_upload_error_text_too_big'),
            upload_error_text_wrong_type: intlGet('report_customization_upload_error_text_wrong_type'),
            kb: intlGet('report_customization_kb'),
        },
        prescribed_actions_customization: {
            prescribed_actions: intlGet('prescribed_actions_customization_prescribed_actions'),
            choose_assessment_type: intlGet('prescribed_actions_customization_choose_assessment_type'),
            add_custom_action: intlGet('prescribed_actions_customization_add_custom_action'),
            merge_in_default_actions: intlGet('prescribed_actions_customization_merge_in_default_actions'),
            confirm_delete_customized_prescribed_action: intlGet('prescribed_actions_customization_confirm_delete_customized_prescribed_action'),
            updating_customized_prescribed_actions: intlGet('prescribed_actions_customization_updating_customized_prescribed_actions'),
            customized_prescribed_actions_saved: intlGet('prescribed_actions_customization_customized_prescribed_actions_saved'),
            customized: intlGet('prescribed_actions_customization_customized'),
            error_unable_to_load_any_templates: intlGet('prescribed_actions_customization_error_unable_to_load_any_templates'),
            error_loading_templates_metadata: intlGet('prescribed_actions_customization_error_loading_templates_metadata'),
            error_loading_template_prescribed_actions: intlGet('prescribed_actions_customization_error_loading_template_prescribed_actions'),
            error_loading_customized_prescribed_actions: intlGet('prescribed_actions_customization_error_loading_customized_prescribed_actions'),
            error_updating_customized_prescribed_actions: intlGet('prescribed_actions_customization_error_updating_customized_prescribed_actions'),
        },
        feedback: {
            dropdown_submit_issue: intlGet('feedback_dropdown_submit_issue'),
            dropdown_feature_request: intlGet('feedback_dropdown_feature_request'),
            loading_assessment_types: intlGet('feedback_loading_assessment_types'),
            dialog_title_placeholder: intlGet('feedback_dialog_title_placeholder'),
            dialog_bug_issue_type_placeholder: intlGet('feedback_dialog_bug_issue_type_placeholder'),
            dialog_feature_request_type_placeholder: intlGet('feedback_dialog_feature_request_type_placeholder'),
            dialog_feature_request_category_placeholder: intlGet('feedback_dialog_feature_request_category_placeholder'),
            dialog_assessment_type_placeholder: intlGet('feedback_dialog_assessment_type_placeholder'),
            dialog_assessment_description_placeholder: intlGet('feedback_dialog_assessment_description_placeholder'),
            dialog_request_description_placeholder: intlGet('feedback_dialog_request_description_placeholder'),
            dialog_expected_behavior_placeholder: intlGet('feedback_dialog_expected_behavior_placeholder'),
            dialog_further_context_placeholder: intlGet('feedback_dialog_further_context_placeholder'),
            submit_request: intlGet('feedback_submit_request'),
            submit_bug_success: intlGet('feedback_submit_bug_success'),
            submit_bug_fail: intlGet('feedback_submit_bug_fail'),
            submit_bug_dialog_submit_button: intlGet('feedback_submit_bug_dialog_submit_button'),
            submit_bug_dialog_header: intlGet('feedback_submit_bug_dialog_header'),
            submit_bug_dialog_title: intlGet('feedback_submit_bug_dialog_title'),
            submit_bug_dialog_issue_type: intlGet('feedback_submit_bug_dialog_issue_type'),
            submit_bug_dialog_description_of_issue: intlGet('feedback_submit_bug_dialog_description_of_issue'),
            submit_bug_dialog_expected_behavior: intlGet('feedback_submit_bug_dialog_expected_behavior'),
            submit_bug_dialog_assessment_type: intlGet('feedback_submit_bug_dialog_assessment_type'),
            submit_bug_dialog_assessment_name: intlGet('feedback_submit_bug_dialog_assessment_name'),
            submit_bug_dialog_troubleshooting_label: intlGet('feedback_submit_bug_dialog_troubleshooting_label'),
            submit_bug_dialog_troubleshooting_hint: intlGet('feedback_submit_bug_dialog_troubleshooting_hint'),
            submit_feature_request_success: intlGet('feedback_submit_feature_request_success'),
            submit_feature_request_fail: intlGet('feedback_submit_feature_request_fail'),
            submit_feature_request_dialog_header: intlGet('feedback_submit_feature_request_dialog_header'),
            submit_feature_request_dialog_title: intlGet('feedback_submit_feature_request_dialog_title'),
            submit_feature_request_dialog_type_of_request: intlGet('feedback_submit_feature_request_dialog_type_of_request'),
            submit_feature_request_dialog_assessment_type: intlGet('feedback_submit_feature_request_dialog_assessment_type'),
            submit_feature_request_dialog_assessment_name: intlGet('feedback_submit_feature_request_dialog_assessment_name'),
            submit_feature_request_dialog_category: intlGet('feedback_submit_feature_request_dialog_category'),
            submit_feature_request_dialog_request_description: intlGet('feedback_submit_feature_request_dialog_request_description'),
            submit_feature_request_dialog_request_outcome: intlGet('feedback_submit_feature_request_dialog_request_outcome'),
            submit_feature_request_dialog_request_more_details: intlGet('feedback_submit_feature_request_dialog_request_more_details'),
            submit_feature_request_dialog_submit_button: intlGet('feedback_submit_feature_request_dialog_submit_button'),
            submit_feature_request_category_newfeature: intlGet('feedback_submit_feature_request_category_newfeature'),
            submit_feature_request_category_newfeature_description: intlGet('feedback_submit_feature_request_category_newfeature_description'),
            submit_feature_request_category_content: intlGet('feedback_submit_feature_request_category_content'),
            submit_feature_request_category_content_description: intlGet('feedback_submit_feature_request_category_content_description'),
            submit_feature_request_category_access: intlGet('feedback_submit_feature_request_category_access'),
            submit_feature_request_category_access_description: intlGet('feedback_submit_feature_request_category_access_description'),
            submit_feature_request_category_assistance: intlGet('feedback_submit_feature_request_category_assistance'),
            submit_feature_request_category_assistance_description: intlGet('feedback_submit_feature_request_category_assistance_description'),
            submit_feature_request_category_onboarding: intlGet('feedback_submit_feature_request_category_onboarding'),
            submit_feature_request_category_onboarding_description: intlGet('feedback_submit_feature_request_category_onboarding_description'),
            submit_feature_request_category_feedback: intlGet('feedback_submit_feature_request_category_feedback'),
            submit_feature_request_category_feedback_description: intlGet('feedback_submit_feature_request_category_feedback_description'),
        },
        manage_templates: {
            requester_guide_link: intlGet('manage_templates_requester_guide_link'),
            template: {
                template: intlGet('manage_templates_template_template'),
                templates: intlGet('manage_templates_template_templates'),
                type: intlGet('manage_templates_template_type'),
                name: intlGet('manage_templates_template_name'),
                version: intlGet('manage_templates_template_version'),
                status: intlGet('manage_templates_template_status'),
                createdAt: intlGet('manage_templates_template_created_at'),
                statuses: {
                    primary: intlGet('manage_templates_template_statuses_primary'),
                    primary_popover_content: intlGet('manage_templates_template_statuses_primary_popover_content'),
                    published: intlGet('manage_templates_template_statuses_published'),
                    published_for_version: intlGet('manage_templates_template_statuses_published_for_version'),
                    published_for_version_popover_content: intlGet('manage_templates_template_statuses_published_for_version_popover_content'),
                    latest_draft_for_version: intlGet('manage_templates_template_statuses_latest_draft_for_version'),
                    draft: intlGet('manage_templates_template_statuses_draft'),
                },
                question_types: {
                    text: intlGet('manage_templates_template_question_types_text'),
                    number: intlGet('manage_templates_template_question_types_number'),
                    yes_no: intlGet('manage_templates_template_question_types_yes_no'),
                    rating: intlGet('manage_templates_template_question_types_rating'),
                    single_selection: intlGet('manage_templates_template_question_types_single_selection'),
                    multi_selection: intlGet('manage_templates_template_question_types_multi_selection'),
                    date: intlGet('manage_templates_template_question_types_date'),
                },
            },
            table: {
                page_title: intlGet('manage_templates_table_page_title'),
                preferences: intlGet('manage_templates_table_preferences'),
                templates_per_page: intlGet('manage_templates_table_templates_per_page'),
                loading_templates: intlGet('manage_templates_table_loading_templates'),
                no_match: intlGet('manage_templates_table_no_match'),
                empty: intlGet('manage_templates_table_empty'),
                error_loading_templates: intlGet('manage_templates_table_error_loading_templates'),
                filter_status: intlGet('manage_templates_table_filter_status'),
                all_templates: intlGet('manage_templates_table_all_templates'),
                published_and_latest_drafts: intlGet('manage_templates_table_published_and_latest_drafts'),
                help_panel: {
                    header: intlGet('manage_templates_table_help_panel_header'),
                    content: intlGet('manage_templates_table_help_panel_content'),
                    link_text: intlGet('manage_templates_table_help_panel_link_text'),
                },
            },
            actions: {
                find_template: intlGet('manage_templates_actions_find_template'),
                create_template: intlGet('manage_templates_actions_create_template'),
                finalize_template: intlGet('manage_templates_actions_finalize_template'),
                create_draft: intlGet('manage_templates_actions_create_draft'),
                publish_for_version: intlGet('manage_templates_actions_publish_for_version'),
                set_primary_template: intlGet('manage_templates_actions_set_primary_template'),
                errors: {
                    error_creating_draft: intlGet('manage_templates_actions_errors_error_creating_draft'),
                    error_publishing_template: intlGet('manage_templates_actions_errors_error_publishing_template'),
                    no_template_selected: intlGet('manage_templates_actions_errors_no_template_selected'),
                    template_already_published: intlGet('manage_templates_actions_errors_template_already_published'),
                    already_primary_template: intlGet('manage_templates_actions_errors_already_primary_template'),
                    cannot_create_draft_from_draft: intlGet('manage_templates_actions_errors_cannot_create_draft_from_draft'),
                },
            },
            publish_template: {
                confirmation: {
                    header: intlGet('manage_templates_publish_template_confirmation_header'),
                    body: intlGet('manage_templates_publish_template_confirmation_body'),
                },
            },
            create_assessment_type: {
                label: intlGet('manage_templates_create_assessment_type_label'),
                description: intlGet('manage_templates_create_assessment_type_description'),
                acronym_field: intlGet('manage_templates_create_assessment_type_acronym_field'),
                invalid_acronym: intlGet('manage_templates_create_assessment_type_invalid_acronym'),
                available: intlGet('manage_templates_create_assessment_type_available'),
                not_available: intlGet('manage_templates_create_assessment_type_not_available'),
                initial_locale_field: intlGet('manage_templates_create_assessment_type_initial_locale_field'),
            },
            wizard: {
                upload_template: {
                    upload_excel_template: intlGet('manage_templates_wizard_upload_template_upload_excel_template'),
                    dropzone_text: intlGet('manage_templates_wizard_upload_template_dropzone_text'),
                    upload_button_text: intlGet('manage_templates_wizard_upload_template_upload_button_text'),
                    upload_info_text1: intlGet('manage_templates_wizard_upload_template_upload_info_text1'),
                    upload_info_linked_text: intlGet('manage_templates_wizard_upload_template_upload_info_linked_text'),
                    upload_info_text2: intlGet('manage_templates_wizard_upload_template_upload_info_text2'),
                    failed_to_parse_template: intlGet('manage_templates_wizard_upload_template_failed_to_parse_template'),
                    template_successfully_uploaded: intlGet('manage_templates_wizard_upload_template_template_successfully_uploaded'),
                    no_template_uploaded: intlGet('manage_templates_wizard_upload_template_no_template_uploaded'),
                    help_panel: {
                        header: intlGet('manage_templates_wizard_upload_template_help_panel_header'),
                        content: intlGet('manage_templates_wizard_upload_template_help_panel_content'),
                    },
                },
                enter_details: {
                    title: intlGet('manage_templates_wizard_enter_details_title'),
                    description: intlGet('manage_templates_wizard_enter_details_description'),
                    details: intlGet('manage_templates_wizard_enter_details_details'),
                    template_name: intlGet('manage_templates_wizard_enter_details_template_name'),
                    template_name_placeholder: intlGet('manage_templates_wizard_enter_details_template_name_placeholder'),
                    template_name_constraint_text: intlGet('manage_templates_wizard_enter_details_template_name_constraint_text'),
                    template_name_error_text: intlGet('manage_templates_wizard_enter_details_template_name_error_text'),
                    template_description: intlGet('manage_templates_wizard_enter_details_template_description'),
                    template_description_placeholder: intlGet('manage_templates_wizard_enter_details_template_description_placeholder'),
                    template_description_constraint_text: intlGet('manage_templates_wizard_enter_details_template_description_constraint_text'),
                    template_description_error_text: intlGet('manage_templates_wizard_enter_details_template_description_error_text'),
                    template_acronym: intlGet('manage_templates_wizard_enter_details_template_acronym'),
                    template_acronym_placeholder: intlGet('manage_templates_wizard_enter_details_template_acronym_placeholder'),
                    template_acronym_constraint_text: intlGet('manage_templates_wizard_enter_details_template_acronym_constraint_text'),
                    template_acronym_error_text_too_long: intlGet('manage_templates_wizard_enter_details_template_acronym_text_too_long'),
                    template_acronym_error_text_not_alphanumeric: intlGet(
                        'manage_templates_wizard_enter_details_template_acronym_text_not_alphanumeric'
                    ),
                    cannot_be_empty: intlGet('manage_templates_wizard_enter_details_template_cannot_be_empty'),
                    must_fill_out_all_required_fields: intlGet('manage_templates_wizard_enter_details_template_must_fill_out_all_required_fields'),
                },
                finalize: {
                    help_panel: {
                        header: intlGet('manage_templates_wizard_finalize_help_panel_header'),
                        content: intlGet('manage_templates_wizard_finalize_help_panel_content'),
                    },
                    no_template_imported: intlGet('manage_templates_wizard_finalize_no_template_imported'),
                    failed_to_upload: intlGet('manage_templates_wizard_finalize_failed_to_upload'),
                    successful_upload_text: intlGet('manage_templates_wizard_finalize_successful_upload_text'),
                    successful_upload_linked_text: intlGet('manage_templates_wizard_finalize_successful_upload_linked_text'),
                    copy_template: intlGet('manage_templates_wizard_finalize_copy_template'),
                    copy_template_success: intlGet('manage_templates_wizard_finalize_copy_template_success'),
                    copy_template_fail: intlGet('manage_templates_wizard_finalize_copy_template_fail'),
                },
            },
            edit: {
                error_loading_template: intlGet('manage_templates_edit_error_loading_template'),
                toggle: intlGet('manage_templates_edit_toggle'),
                template: intlGet('manage_templates_edit_template'),
                metadata: intlGet('manage_templates_edit_metadata'),
                add_locale: intlGet('manage_templates_edit_add_locale'),
                select_locale_placeholder: intlGet('manage_templates_edit_select_locale_placeholder'),
                request_language: intlGet('manage_templates_edit_request_language'),
                modify_structure: {
                    button_label: intlGet('manage_templates_edit_modify_structure_button_label'),
                    sections_heading: intlGet('manage_templates_edit_modify_structure_sections_heading'),
                    add_section: intlGet('manage_templates_edit_modify_structure_add_section'),
                    add_section_modal_content: intlGet('manage_templates_edit_modify_structure_add_section_modal_content'),
                    delete_section: intlGet('manage_templates_edit_modify_structure_delete_section'),
                    delete_section_warning: intlGet('manage_templates_edit_modify_structure_delete_section_warning'),
                    categories_heading: intlGet('manage_templates_edit_modify_structure_categories_heading'),
                    add_category: intlGet('manage_templates_edit_modify_structure_add_category'),
                    add_category_modal_content: intlGet('manage_templates_edit_modify_structure_add_category_modal_content'),
                    delete_category: intlGet('manage_templates_edit_modify_structure_delete_category'),
                    delete_category_warning: intlGet('manage_templates_edit_modify_structure_delete_category_warning'),
                    questions_heading: intlGet('manage_templates_edit_modify_structure_questions_heading'),
                    change_question_type: intlGet('manage_templates_edit_modify_structure_change_question_type'),
                    add_question: intlGet('manage_templates_edit_modify_structure_add_question'),
                    delete_question: intlGet('manage_templates_edit_modify_structure_delete_question'),
                    delete_question_warning: intlGet('manage_templates_edit_modify_structure_delete_question_warning'),
                    button_disabled_reasons: {
                        no_section: intlGet('manage_templates_edit_modify_structure_button_disabled_reasons_no_section'),
                        delete_categories_first: intlGet('manage_templates_edit_modify_structure_button_disabled_reasons_delete_categories_first'),
                        cannot_delete_last_section: intlGet(
                            'manage_templates_edit_modify_structure_button_disabled_reasons_cannot_delete_last_section'
                        ),
                        no_category: intlGet('manage_templates_edit_modify_structure_button_disabled_reasons_no_category'),
                        delete_questions_first: intlGet('manage_templates_edit_modify_structure_button_disabled_reasons_delete_questions_first'),
                        no_question: intlGet('manage_templates_edit_modify_structure_button_disabled_reasons_no_question'),
                        navigate_to_last_question: intlGet(
                            'manage_templates_edit_modify_structure_button_disabled_reasons_navigate_to_last_question'
                        ),
                    },
                },
            },
            editable_fields: {
                supports_html: intlGet('manage_templates_editable_fields_supports_html'),
                assessment_help_panel: {
                    category_context: intlGet('manage_templates_editable_fields_assessment_help_panel_category_context'),
                    prompt_context: intlGet('manage_templates_editable_fields_assessment_help_panel_prompt_context'),
                },
            },
            update_template_info: {
                link_text: intlGet('manage_templates_update_template_info_link_text'),
                template_name: intlGet('manage_templates_update_template_info_template_name'),
                delivery_guidance_link: intlGet('manage_templates_update_template_info_delivery_guidance_link'),
                delivery_guidance_help_context: intlGet('manage_templates_update_template_info_delivery_guidance_help_context'),
                help_panel: {
                    header: intlGet('manage_templates_update_template_info_help_panel_header'),
                    content: intlGet('manage_templates_update_template_info_help_panel_content'),
                },
            },
            configure_template: {
                link_text: intlGet('manage_templates_configure_template_link_text'),
                error_updating_configuration: intlGet('manage_templates_configure_template_error_updating_configuration'),
                find_setting: intlGet('manage_templates_configure_template_find_setting'),
                name: intlGet('manage_templates_configure_template_name'),
                description: intlGet('manage_templates_configure_template_description'),
                value: intlGet('manage_templates_configure_template_value'),
                help_panel: {
                    header: intlGet('manage_templates_configure_template_help_panel_header'),
                    content: intlGet('manage_templates_configure_template_help_panel_content'),
                },
                editableDefaults: {
                    enable_pre_event: {
                        name: intlGet('manage_templates_configure_template_editable_defaults_enable_pre_event_name'),
                        description: intlGet('manage_templates_configure_template_editable_defaults_enable_pre_event_description'),
                    },
                    enable_live_polling: {
                        name: intlGet('manage_templates_configure_template_editable_defaults_enable_live_polling_name'),
                        description: intlGet('manage_templates_configure_template_editable_defaults_enable_live_polling_description'),
                    },
                    rating_na_enabled: {
                        name: intlGet('manage_templates_configure_template_editable_defaults_rating_na_enabled_name'),
                        description: intlGet('manage_templates_configure_template_editable_defaults_rating_na_enabled_description'),
                    },
                    snapshots_disabled: {
                        name: intlGet('manage_templates_configure_template_editable_defaults_snapshots_disabled_name'),
                        description: intlGet('manage_templates_configure_template_editable_defaults_snapshots_disabled_description'),
                    },
                    radar_disabled: {
                        name: intlGet('manage_templates_configure_template_editable_defaults_radar_disabled_name'),
                        description: intlGet('manage_templates_configure_template_editable_defaults_radar_disabled_description'),
                    },
                    heatmap_disabled: {
                        name: intlGet('manage_templates_configure_template_editable_defaults_heatmap_disabled_name'),
                        description: intlGet('manage_templates_configure_template_editable_defaults_heatmap_disabled_description'),
                    },
                    score_bar_chart_disabled: {
                        name: intlGet('manage_templates_configure_template_editable_defaults_score_bar_chart_disabled_name'),
                        description: intlGet('manage_templates_configure_template_editable_defaults_score_bar_chart_disabled_description'),
                    },
                    ppt_disabled: {
                        name: intlGet('manage_templates_configure_template_editable_defaults_ppt_disabled_name'),
                        description: intlGet('manage_templates_configure_template_editable_defaults_ppt_disabled_description'),
                    },
                    word_disabled: {
                        name: intlGet('manage_templates_configure_template_editable_defaults_word_disabled_name'),
                        description: intlGet('manage_templates_configure_template_editable_defaults_word_disabled_description'),
                    },
                    excel_disabled: {
                        name: intlGet('manage_templates_configure_template_editable_defaults_excel_disabled_name'),
                        description: intlGet('manage_templates_configure_template_editable_defaults_excel_disabled_description'),
                    },
                },
            },
            share: {
                share_template: intlGet('manage_templates_share_share_template'),
                error_sharing_template: intlGet('manage_templates_share_error_sharing_template'),
                owner: intlGet('manage_templates_share_owner'),
                coowners: intlGet('manage_templates_share_coowners'),
                add_coowner: intlGet('manage_templates_share_add_coowner'),
                a2t_product_team: intlGet('manage_templates_share_a2t_product_team'),
                locale_managers: intlGet('manage_templates_share_locale_managers'),
                add_locale_manager: intlGet('manage_templates_share_add_locale_manager'),
                assessment_creators: intlGet('manage_templates_share_assessment_creators'),
                add_assessment_creator: intlGet('manage_templates_share_add_assessment_creator'),
                all_partners: intlGet('manage_templates_share_all_partners'),
                pending_save: intlGet('manage_templates_share_pending_save'),
                share_template_help_panel: {
                    header: intlGet('manage_templates_share_share_template_help_panel_header'),
                    content: intlGet('manage_templates_share_share_template_help_panel_content'),
                },
            },
            template_publication_info_modal: {
                content: intlGet('manage_templates_template_publication_info_modal_content'),
                header: intlGet('manage_templates_template_publication_info_modal_header'),
            },
        },
    } as AppLabels;
};
